<template>
  <div class="stask-zone-input">
    <div v-if="!disable && edit">
      <datetime-input :offset="[0, 30]" @enter="save" ref="input" autofocus input-class="app-input" date-type="datetime" date-format="##/##/#### ##:##"
                      v-model.lazy="model"
                      simpleBorder/>
      <div class="btns-min m-t-xs">
        <u-btn @click="save" label="Salvar" class="btn-t min bg-primary text-white" no-caps flat />
        <u-btn @click="cancel" label="Cancelar" class="btn-t min bg-grey-3 text-blue-grey m-l-xs" no-caps flat />
      </div>
    </div>
    <div class="input-fake" @click="clickEdit" v-else>
      <div v-if="task.deadline" class="task-date">
        <span :class="{'m-l-xs': !disable}">{{ task.deadline.date|formatDateDistance }}</span> <!-- color by date -->
        <u-chip small v-if="compareDate(task.deadline) !== 0" :color="colorize(task.deadline)" square
                class="capitalize font-12">
          {{ task.deadline|formatDate('MMMM') }}, {{ task.deadline|formatDate('dd') }},
          {{ task.deadline|formatDate('HH:mm') }}
        </u-chip> <!-- click open calendar --> <!-- color by date -->
        <u-chip small color="deep-orange" v-else-if="isToday(task.deadline.date)" square class="capitalize">
          Hoje, {{ task.deadline|formatDate('HH:mm') }}
        </u-chip>
        <u-chip small color="deep-orange" v-else square class="capitalize">
          Amanhã, {{ task.deadline|formatDate('HH:mm') }}
        </u-chip>
      </div>
      <div v-else>
        <div>Sem previsão</div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskMixin from "components/suporte/components/task/include/TaskMixin"
import TaskHelper from "components/suporte/components/task/include/TaskHelper"
import TaskZoneInput from "components/suporte/components/task/include/TaskZoneInput"
import DatetimeInput from "@/reuse/input/Datetime"
import {date, UChip} from "uloc-vue"
import {updateTask} from "@/domain/tarefas/services"
import {datetimeToEn} from "@/utils/date"

export default {
  name: "Deadline",
  mixins: [TaskZoneInput, TaskMixin, TaskHelper],
  props: {
    disable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    __reset () {
      this.model = this.task.deadline ? date.formatDate(this.task.deadline.date, 'DD/MM/YYYY HH:mm') : null
    },
    change () {},
    save () {
      const v = this.model
      let deadline
      try {
        if (v) {
        deadline = datetimeToEn(v)
        } else {
          deadline = null
        }
      } catch (e) {
        alert('Data inválida')
        return
      }
      this.$uloc.dialog({
        title: 'Confirmar registro de prazo',
        message: 'Alterar data limite para entrega da tarefa para ' + v + '?',
        ok: 'Registrar data',
        cancel: 'Cancelar',
        color: 'black',
        noCaps: true
      })
          .then(() => {
            const old = this.task.deadline
            this.loading = true
            updateTask({
              id: this.task.id,
              deadline: deadline,
              notify: 'updateDeadline'
            }, 'PATCH')
                .then(response => {
                  this.loading = false
                  this.reset()
                  this.$emit('update', v)
                })
                .catch(error => {
                  this.loading = false
                  this.task.deadline = old
                  this.alertApiError(error)
                })
            this.task.deadline = deadline ? {date: deadline} : null
          })
          .catch(() => {
          })
    }
  },
  components: {DatetimeInput, UChip}
}
</script>

